import * as React from "react";
import { Skeleton, Stack, Box } from "@mui/material";

const ProfilePromptResponseSkeleton: React.FC = () => {
  return (
    <Stack spacing={1} style={{ width: "100%" }}>
      <Skeleton variant="text" sx={{ fontSize: "2rem" }} />
      <Skeleton variant="rectangular" height={60} />
      <Skeleton variant="rounded" height={60} />
      <Stack
        direction="row"
        spacing={2}
        alignItems={"center"}
        justifyContent={"center"}
      >
        <Skeleton variant="rounded" width={90} height={40} />
        <Skeleton variant="rounded" width={90} height={40} />
        <Skeleton variant="rounded" width={90} height={40} />
      </Stack>
    </Stack>
  );
};

export default ProfilePromptResponseSkeleton;
