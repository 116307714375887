import React, { useState } from "react";
import {
  FormControl,
  MenuItem,
  Select,
  SelectChangeEvent,
  InputLabel,
} from "@mui/material";
import { Prompt, AuditOutput } from "../types";

interface PromptSelectDropdownProps {
  selectedPrompt: AuditOutput;
  prompts: Prompt[];
  onPromptChange: (questionId: string) => void;
}

const PromptSelectDropdown: React.FC<PromptSelectDropdownProps> = ({
  prompts,
  selectedPrompt,
  onPromptChange,
}) => {
  const [selectedOption, setSelectedOption] = useState(
    selectedPrompt.questionId
  );

  const handleOptionChange = (event: SelectChangeEvent<string>) => {
    // setSelectedOption(event.target.value as string);
    onPromptChange(event.target.value as string);
  };

  return (
    <FormControl style={{ width: "200px" }}>
      <InputLabel htmlFor="my-select">Respond to...</InputLabel>
      <Select
        value={selectedOption}
        onChange={handleOptionChange}
        label="Respond to..."
        inputProps={{ id: "my-select" }}
      >
        {prompts.map((prompt: Prompt, index: number) => (
          <MenuItem key={index} value={prompt.questionId}>
            {prompt.prompt}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

export default PromptSelectDropdown;
