import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { Typography } from "@mui/material";

interface TypingEffectProps {
  sentence: string;
  words: string[];
  typingInterval?: number;
  cyclingInterval?: number;
}

const TypeContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

const CursorSpan = styled.span`
  border-right: 2px solid;
  animation: blink 1s step-start infinite;

  @keyframes blink {
    50% {
      border-color: transparent;
    }
  }
`;

const TypingEffect: React.FC<TypingEffectProps> = ({
  sentence,
  words,
  typingInterval = 150,
  cyclingInterval = 2000,
}) => {
  const [index, setIndex] = useState(0);
  const [subIndex, setSubIndex] = useState(0);
  const [blink, setBlink] = useState(true);
  const [currentWord, setCurrentWord] = useState("");

  useEffect(() => {
    if (index === words.length) {
      setIndex(0);
    }

    const word = words[index];

    if (subIndex === word.length + 1 && index < words.length) {
      setBlink(true);
      const timeout = setTimeout(() => {
        setIndex((prevIndex) => (prevIndex + 1) % words.length);
        setSubIndex(0);
      }, cyclingInterval);

      return () => clearTimeout(timeout);
    } else {
      setBlink(false);
      const timeout = setTimeout(() => {
        setCurrentWord((prev) => prev + word.charAt(subIndex));
        setSubIndex((prev) => prev + 1);
      }, typingInterval);

      return () => clearTimeout(timeout);
    }
  }, [subIndex, index, words, typingInterval, cyclingInterval]);

  useEffect(() => {
    setCurrentWord("");
  }, [index]);

  return (
    <TypeContainer>
      <Typography variant="h3" fontWeight={600}>
        {sentence}
      </Typography>
      <Typography variant="h3" fontWeight={600}>
        <CursorSpan>{currentWord}</CursorSpan>
      </Typography>
    </TypeContainer>
  );
};

export default TypingEffect;
