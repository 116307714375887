import React from "react";
import Logo from "./gptmatchme.png";
import styled from "styled-components";
import {
  createTheme,
  ThemeProvider,
  CssBaseline,
  AppBar,
  Toolbar,
  Typography,
  Box,
  Grid,
  useTheme,
} from "@mui/material";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import "./App.css";
import { AuthProvider, useAuth } from "./auth/AuthContext";
import { PrivateRoute } from "./components";
import {
  Login,
  Dashboard,
  Account,
  Onboarding,
  PasswordReset,
  Home,
} from "./pages";
import { Route, Routes, Link, BrowserRouter as Router } from "react-router-dom";

const FooterItemBox = styled(Box)`
  flex-direction: row;
  align-items: center;
  display: flex;
  justify-content: center;
`;

const appStyles: React.CSSProperties = {
  display: "flex",
  flexDirection: "column",
  minHeight: "100vh",
};

const contentStyles: React.CSSProperties = {
  flex: 1,
};

const LinkContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
`;

const themeLight = createTheme({
  palette: {
    primary: { main: "#202046", contrastText: "#fff" },
    secondary: { main: "#FE633D", contrastText: "#fff" },
    background: {
      default: "#ddd",
    },
  },
});

const themeDark = createTheme({
  palette: {
    primary: { main: "#000", contrastText: "#fff" },
    background: {
      default: "#282c34",
    },
  },
});

function NavigationHeader() {
  const { isAuthenticated } = useAuth();
  const theme = useTheme();
  return (
    <AppBar position="static" color="default">
      <Toolbar>
        <Link
          to={"/"}
          style={{
            flex: 1,
            color: "white",
            textDecoration: "none",
            display: "flex",
            flexDirection: "column",
          }}
        >
          <img
            style={{ width: 120, objectFit: "contain" }}
            src={Logo}
            alt={"GPTMatch.me logo"}
          />
        </Link>
        {isAuthenticated && (
          <LinkContainer>
            <Link
              to="/dashboard"
              style={{
                color: theme.palette.primary.main,
                textDecoration: "none",
              }}
            >
              Dashboard
            </Link>
            <Link
              to="/account"
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
                color: theme.palette.primary.main,
                textDecoration: "none",
                marginLeft: "16px",
              }}
            >
              <AccountCircleIcon />
            </Link>
          </LinkContainer>
        )}
      </Toolbar>
    </AppBar>
  );
}

function NavigationFooter() {
  const { isAuthenticated } = useAuth();
  return (
    <Box
      component="footer"
      style={{
        backgroundColor: "#fff",
        minHeight: "80px",
      }}
    >
      <Grid
        container
        style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: isAuthenticated ? "center" : "flex-start",
          alignItems: "center",
          minHeight: "80px",
        }}
      >
        {isAuthenticated && (
          <Grid item xs={12} sm={6}>
            <FooterItemBox>
              <Link
                target="_blank"
                to="https://datai-public.s3.us-east-2.amazonaws.com/GPTMatch.me+privacy+policy.pdf"
                style={{ color: "black", textDecoration: "none" }}
              >
                Privacy Policy
              </Link>
              <Link
                target="_blank"
                to="https://datai-public.s3.us-east-2.amazonaws.com/GPTMatch.me+Terms+of+Service.pdf"
                style={{
                  color: "black",
                  textDecoration: "none",
                  marginLeft: "20px",
                }}
              >
                Terms of Service
              </Link>
              <div>
                <a
                  style={{
                    color: "black",
                    textDecoration: "none",
                    marginLeft: "20px",
                  }}
                  href="mailto:support@summerlemonade.xyz"
                >
                  Contact Us
                </a>
              </div>
            </FooterItemBox>
          </Grid>
        )}
        <Grid item xs={12} sm={6}>
          <Typography variant="body2" style={{ marginLeft: 20 }}>
            © {new Date().getFullYear()} Summer Lemonade Inc.
          </Typography>
        </Grid>
        {!isAuthenticated && (
          <Grid item xs={12} sm={6}>
            <div>
              <a
                style={{
                  color: "black",
                  textDecoration: "none",
                }}
                href="mailto:support@summerlemonade.xyz"
              >
                Contact Us
              </a>
            </div>
          </Grid>
        )}
      </Grid>
    </Box>
  );
}

const privatize = (children: React.ReactNode) => {
  return <PrivateRoute>{children}</PrivateRoute>;
};

function App() {
  return (
    <ThemeProvider theme={themeLight}>
      <CssBaseline />
      <Router>
        <AuthProvider>
          <div style={appStyles}>
            <NavigationHeader />
            <div style={contentStyles}>
              <Routes>
                <Route path="/" element={<Home />} />
                <Route path="/login" element={<Login />} />
                <Route path="/reset-password" element={<PasswordReset />} />
                <Route path="/onboarding" element={privatize(<Onboarding />)} />
                <Route path="/dashboard" element={privatize(<Dashboard />)} />
                <Route path="/account" element={privatize(<Account />)} />
              </Routes>
            </div>
            <NavigationFooter />
          </div>
        </AuthProvider>
      </Router>
    </ThemeProvider>
  );
}

export default App;
