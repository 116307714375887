import React, { useState } from "react";
import {
  TextField,
  Button,
  Container,
  Grid,
  Typography,
  CircularProgress,
} from "@mui/material";
import { useLocation, useNavigate } from "react-router-dom";
import { useAuth } from "../auth/AuthContext";

import { get, post } from "../api";

const PasswordReset: React.FC = () => {
  const navigate = useNavigate();
  const { logout } = useAuth();
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const uid = searchParams.get("uid");
  const token = searchParams.get("token");

  const [fieldValue, setFieldValue] = useState("");
  const [responseMessage, setResponseMessage] = useState<string>("");
  const [isLoading, setIsLoading] = useState(false);

  const handleTextFieldChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setFieldValue(e.target.value);
  };

  const handleSubmit = async () => {
    setIsLoading(true);
    if (uid && token) {
      const { result, status } = await post(
        "/reset-password/",
        { uid, token, password: fieldValue },
        logout
      );

      navigate("/login");
    } else {
      const { result, status } = await get("/reset-password/", logout, {
        email: fieldValue,
      });
      setResponseMessage(result.message);
    }
    setIsLoading(false);
  };

  return (
    <Container maxWidth="xs">
      <div>
        <h2>Password Reset</h2>
        <form>
          <Grid container spacing={2}>
            {uid && token ? (
              <Grid item xs={12}>
                <TextField
                  label="Password"
                  type="password"
                  variant="outlined"
                  fullWidth
                  value={fieldValue}
                  onChange={handleTextFieldChange}
                />
              </Grid>
            ) : (
              <Grid item xs={12}>
                <TextField
                  label="Email"
                  variant="outlined"
                  fullWidth
                  value={fieldValue}
                  onChange={handleTextFieldChange}
                />
              </Grid>
            )}

            <Grid item xs={12}>
              <Typography variant="body1" color="success">
                {responseMessage}
              </Typography>
            </Grid>
            <Grid
              item
              xs={12}
              style={{ display: "flex", justifyContent: "center" }}
            >
              {isLoading && <CircularProgress />}
              {!isLoading && !responseMessage && (
                <Button
                  variant="contained"
                  color="primary"
                  fullWidth
                  onClick={handleSubmit}
                >
                  {uid && token ? "Set Password" : "Send Reset Email"}
                </Button>
              )}
            </Grid>
          </Grid>
        </form>
      </div>
    </Container>
  );
};

export default PasswordReset;
