import React, { useState } from "react";
import styled from "styled-components";
import {
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  TextField,
} from "@mui/material";
import Confetti from "react-confetti";
import { post } from "../api";
import { useAuth } from "../auth/AuthContext";

const StyledDialogContent = styled(DialogContent)`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 400px;
  height: 200px;
  @media (max-width: 1024px) {
    width: 300px;
    height: 200px;
  }
`;

const StyledConfetti = styled(Confetti)`
  width: 400px;
  height: 200px;
  @media (max-width: 1024px) {
    width: 300px;
    height: 200px;
  }
`;

type WaitlistProps = {
  open: boolean;
  onClose: () => void;
};

const WaitlistComponent: React.FC<WaitlistProps> = ({ open, onClose }) => {
  const { logout } = useAuth();
  const [email, setEmail] = useState("");
  const [submitted, setSubmitted] = useState(false);
  const [responseMessage, setResponseMessage] = useState("");

  const handleEmailChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setEmail(event.target.value);
  };

  const isValidEmail = (email: string): boolean => {
    const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return regex.test(email);
  };

  const handleSubmit = async () => {
    const { result, status } = await post(
      `/waitlist/`,
      {
        email,
      },
      logout
    );
    setResponseMessage(result.message);
    setSubmitted(true);
  };

  return (
    <Dialog open={open} onClose={onClose}>
      {!submitted && <DialogTitle>Join the Waitlist</DialogTitle>}
      <StyledDialogContent>
        {!submitted ? (
          <>
            <TextField
              autoFocus
              margin="dense"
              id="email"
              label="Email Address"
              type="email"
              fullWidth
              variant="outlined"
              value={email}
              onChange={handleEmailChange}
            />
            <Button
              onClick={handleSubmit}
              variant="contained"
              color="secondary"
              style={{ marginTop: "20px" }}
              disabled={!isValidEmail(email)}
            >
              Submit
            </Button>
          </>
        ) : (
          <>
            <StyledConfetti />
            <div>
              <p>Thank you for joining!</p>
              <p>{responseMessage}</p>
            </div>
          </>
        )}
      </StyledDialogContent>
    </Dialog>
  );
};

export default WaitlistComponent;
