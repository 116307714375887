import React, { useState } from "react";
import { Button, CircularProgress, Typography, Rating } from "@mui/material";
import styled from "styled-components";
import { post } from "../api";
import { ProfileResponse, AuditOutput } from "../types";
import { useAuth } from "../auth/AuthContext";
import {
  ProfilePromptResponse,
  ProfilePromptResponseSkeleton,
  PromptSelectDropdown,
} from "./";

const ProfileContainerContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const ProfileImage = styled.img`
  max-height: 420px;
  object-fit: contain;
  border-radius: 8px;
`;

const ActionContainer = styled.div`
  display: flex;
  justify-content: space-evenly;
  width: 100%;
  margin-top: 16px;
`;

const DecisionContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

interface ProfileContainerProps {
  profileData: ProfileResponse;
  auditMode: boolean;
}

const ProfileContainer: React.FC<ProfileContainerProps> = ({
  profileData,
  auditMode,
}) => {
  const { logout } = useAuth();
  const [ratingValue, setRatingValue] = React.useState<number>(0);
  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingPrompt, setIsLoadingPrompt] = useState(false);
  const [auditOutput, setAuditOutput] = useState<AuditOutput | null>(null);
  const [decisionMessage, setDecisionMessage] = useState("");
  const [liked, setLiked] = useState(false);

  const handlePromptSelectionChange = async (questionId: string) => {
    handleYes(questionId);
  };

  const handleSkip = async () => {
    handleInitiate("skip");
  };

  const handleInitiate = async (action: string) => {
    setIsLoading(true);
    const { result, status } = await post(
      `/initiate/`,
      {
        action: action,
        profile: profileData,
        auditOutput: auditOutput,
      },
      logout
    );

    if (result && result.message) {
      setDecisionMessage(result.message);
    }
    setIsLoading(false);
  };

  const handleYes = async (questionId?: string) => {
    if (auditMode) {
      setIsLoadingPrompt(true);
      const { result, status } = await post(
        `/audit/`,
        {
          profile: profileData,
          questionId: questionId,
        },
        logout
      );

      if (result && result.message && !result.promptAudit) {
        setDecisionMessage(result.message);
      } else if (result && result.promptAudit) {
        setAuditOutput(result.promptAudit);
      }
      setIsLoadingPrompt(false);
    } else {
      handleInitiate("yes");
      setLiked(true);
    }
  };

  const handleAnswerEdit = (newAnswer: string) => {
    setAuditOutput((prev: AuditOutput | null) => {
      if (!prev) {
        return null;
      } else {
        return { ...prev, promptNote: newAnswer };
      }
    });
  };

  const handleConfirm = async () => {
    handleInitiate("yes");
    setLiked(true);
  };

  const handleRatingChange = async (event: any, newValue: number | null) => {
    if (newValue !== null) {
      setRatingValue(newValue);
    }

    const { result, status } = await post(
      `/audit/ratings/`,
      {
        rating: newValue,
        prompt: auditOutput?.prompt,
        promptAnswer: auditOutput?.promptAnswer,
        promptNote: auditOutput?.promptNote,
      },
      logout
    );
  };

  const renderRating = () => {
    return (
      liked && (
        <DecisionContainer>
          <p>How would you rate the response?</p>{" "}
          <Rating
            disabled={ratingValue > 0}
            value={ratingValue}
            onChange={handleRatingChange}
          />
        </DecisionContainer>
      )
    );
  };

  const renderActionArea = () => {
    if (!isLoading && !isLoadingPrompt) {
      if (decisionMessage) {
        return (
          <DecisionContainer>
            <p>{decisionMessage}</p>
            {renderRating()}
          </DecisionContainer>
        );
      } else if (!auditMode || (auditMode && !auditOutput)) {
        return (
          <ActionContainer>
            <Button variant="outlined" onClick={handleSkip} color="primary">
              Skip
            </Button>
            <Button
              variant="contained"
              onClick={() => handleYes()}
              color="secondary"
            >
              Yes
            </Button>
          </ActionContainer>
        );
      } else {
        return (
          <ActionContainer>
            <Button
              variant="outlined"
              color="error"
              onClick={() => setAuditOutput(null)}
            >
              Cancel
            </Button>
            <Button
              variant="outlined"
              onClick={() => handleYes()}
              color="primary"
            >
              Retry
            </Button>
            <Button
              variant="contained"
              onClick={handleConfirm}
              color="secondary"
            >
              Send like
            </Button>
          </ActionContainer>
        );
      }
    }
  };

  return (
    <ProfileContainerContainer>
      <Typography variant="h5" fontWeight={600}>
        {profileData.profile.firstName}, {profileData.profile.age}
      </Typography>

      <ProfileImage
        src={profileData.profile.photos[0].url}
        alt={`Profile ${profileData.identityId}`}
      />
      {isLoading && <CircularProgress style={{ marginTop: "16px" }} />}
      {isLoadingPrompt && <ProfilePromptResponseSkeleton />}
      {auditOutput && !decisionMessage && !isLoading && !isLoadingPrompt && (
        <div
          style={{
            marginTop: "16px",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <PromptSelectDropdown
            prompts={auditOutput.allPrompts}
            selectedPrompt={auditOutput}
            onPromptChange={handlePromptSelectionChange}
          />
          <ProfilePromptResponse
            data={auditOutput}
            onAnswerEdit={handleAnswerEdit}
          />
        </div>
      )}
      {renderActionArea()}
    </ProfileContainerContainer>
  );
};

export default ProfileContainer;
