// src/auth/AuthContext.tsx
import React, { createContext, useContext, useState } from "react";
import { RouteProps, useNavigate } from "react-router-dom";

interface AuthContextType {
  isAuthenticated: boolean;
  login: (sessionKey: string) => void;
  logout: (isHardLogout: boolean) => void;
}

interface AuthProviderProps {
  children: React.ReactNode;
}

const AuthContext = createContext<AuthContextType | undefined>(undefined);

export const useAuth = () => {
  const context = useContext(AuthContext);
  if (!context) {
    throw new Error("useAuth must be used within an AuthProvider");
  }
  return context;
};

export const AuthProvider: React.FC<AuthProviderProps> = ({ children }) => {
  const navigate = useNavigate();
  const isAuthenticated = () => {
    return localStorage.getItem("dataisession") ? true : false;
  };

  const login = (sessionKey: string) => {
    localStorage.setItem("dataisession", sessionKey);
  };

  const logout = (isHardLogout: boolean) => {
    if (isHardLogout) {
      localStorage.removeItem("dataisession");
      navigate("/login");
    } else {
      navigate("/account");
    }
  };

  return (
    <AuthContext.Provider
      value={{ isAuthenticated: isAuthenticated(), login, logout }}
    >
      {children}
    </AuthContext.Provider>
  );
};
