import React, { useState } from "react";
import styled from "styled-components";
import {
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  TextField,
  IconButton,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { post } from "../api";
import { useAuth } from "../auth/AuthContext";

const StyledDialogContent = styled(DialogContent)`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 400px;
  height: 200px;
  @media (max-width: 1024px) {
    width: 300px;
    height: 200px;
  }
`;

type SignupProps = {
  open: boolean;
  onClose: () => void;
};

const Signup: React.FC<SignupProps> = ({ open, onClose }) => {
  const { logout } = useAuth();
  const [email, setEmail] = useState("");
  const [submitted, setSubmitted] = useState(false);
  const [responseMessage, setResponseMessage] = useState("");

  const handleEmailChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setEmail(event.target.value);
  };

  const isValidEmail = (email: string): boolean => {
    const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return regex.test(email);
  };

  const handleSubmit = async () => {
    const { result, status } = await post(
      `/sign-up/`,
      {
        email,
      },
      logout
    );
    if (!result) {
      setResponseMessage("A user with this email already exists.");
    } else {
      setResponseMessage(result.message);
      setSubmitted(true);
    }
  };

  return (
    <Dialog open={open} onClose={onClose}>
      {!submitted && (
        <DialogTitle style={{ fontWeight: 500 }}>
          Start your <b>free</b> trial. Cancel anytime.
        </DialogTitle>
      )}
      <IconButton
        aria-label="close"
        onClick={onClose}
        sx={{
          position: "absolute",
          right: 8,
          top: 8,
          color: (theme) => theme.palette.grey[500],
        }}
      >
        <CloseIcon />
      </IconButton>
      <StyledDialogContent>
        {!submitted ? (
          <>
            <TextField
              autoFocus
              margin="dense"
              id="email"
              label="Email Address"
              type="email"
              fullWidth
              variant="outlined"
              value={email}
              onChange={handleEmailChange}
            />
            <Button
              onClick={handleSubmit}
              variant="contained"
              color="secondary"
              style={{ marginTop: "20px" }}
              disabled={!isValidEmail(email)}
            >
              Submit
            </Button>
            <p>{responseMessage}</p>
          </>
        ) : (
          <>
            <div>
              <p>Thank you for joining!</p>
              <p>{responseMessage}</p>
            </div>
          </>
        )}
      </StyledDialogContent>
    </Dialog>
  );
};

export default Signup;
