import React, { useState } from "react";
import styled from "styled-components";
import { Paper, Typography, TextField, Box } from "@mui/material";
import { ProfilePromptResponseSkeleton } from "./";

const PromptContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

interface ProfilePromptResponseProps {
  data: any;
  onAnswerEdit: any;
}

const ProfilePromptResponse: React.FC<ProfilePromptResponseProps> = ({
  data,
  onAnswerEdit,
}) => {
  const [val, setVal] = useState(data.promptNote);

  const handleEdit = (e: React.ChangeEvent<HTMLInputElement>) => {
    setVal(e.target.value);
    onAnswerEdit(e.target.value);
  };

  return (
    <PromptContainer>
      <Paper
        elevation={3}
        style={{ padding: "20px", marginTop: "16px", borderRadius: "16px" }}
      >
        <Typography variant="body1">{data.prompt}</Typography>
        <Typography variant="h6" style={{ marginTop: "10px", fontWeight: 600 }}>
          {data.promptAnswer}
        </Typography>
        <Box marginTop={2}>
          <TextField
            value={val}
            fullWidth
            label="Your comment"
            multiline
            variant="outlined"
            placeholder="Add a comment..."
            onChange={handleEdit}
          />
        </Box>
      </Paper>
    </PromptContainer>
  );
};

export default ProfilePromptResponse;
