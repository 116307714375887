import React, { useState, useEffect } from "react";
import {
  Button,
  CircularProgress,
  FormControlLabel,
  Grid,
  Switch,
  Typography,
  Backdrop,
  useTheme,
} from "@mui/material";
import throttle from "lodash/throttle";
import { ProfileContainer } from "../components";
import styled from "styled-components";
import { get } from "../api";
import { ProfileResponse } from "../types";
import { useAuth } from "../auth/AuthContext";

const DashboardContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 32px;
`;

const UpperRightButtonsContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  width: 100%;
  margin-bottom: 32px;
`;

const CenterButtonContainer = styled.div`
  margin-top: 32px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

interface Location {
  latitude: number | null;
  longitude: number | null;
}

const Dashboard: React.FC = () => {
  const theme = useTheme();
  const { logout } = useAuth();

  // TODO: Make this dynamic
  // const [auditMode, setAuditMode] = useState(
  //   localStorage.getItem("isAuditModeEnabled") === "true"
  // );
  const [auditMode, setAuditMode] = useState(true);
  const [isFullScreenLoading, setIsFullScreenLoading] = useState(false);
  const [responseMessage, setResponseMessage] = useState("");
  const [profiles, setProfiles] = useState<ProfileResponse[]>([]);
  const [location, setLocation] = useState<Location>({
    latitude: null,
    longitude: null,
  });

  const fetchLocation = (callback: ((location: Location) => void) | null) => {
    if ("geolocation" in navigator) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          const { latitude, longitude } = position.coords;
          setLocation({ latitude, longitude });
          if (callback) {
            callback({ latitude, longitude });
          }
        },
        (error) => {
          console.error("Error getting user's location:", error.message);
        }
      );
    } else {
      console.error("Geolocation is not available in this browser.");
    }
  };

  const fetchLocationAndData = () => {
    if (location.latitude && location.longitude) {
      _fetchData(location);
      return;
    } else {
      setIsFullScreenLoading(true);
      fetchLocation(_fetchData);
      setIsFullScreenLoading(false);
    }
  };

  const _fetchData = async (location: Location) => {
    if (!location.latitude || !location.longitude) {
      alert(
        "We need your location to show you profiles. Please enable location services in your browser and click the browser refresh button once you have enabled location."
      );
      return;
    }
    setIsFullScreenLoading(true);
    const { result, status } = await get(`/profiles/`, logout, {
      lat: location.latitude.toString(),
      lng: location.longitude?.toString(),
    });
    if (result && result.message) {
      setResponseMessage(result.message);
      return;
    } else if (result && result.length === 0) {
      setResponseMessage("No profiles found. Please try again later.");
    } else if (result && result.length > 0) {
      setProfiles(result);
    }
    setIsFullScreenLoading(false);
  };
  const fetchData = throttle(fetchLocationAndData, 5000, { leading: true });

  const renderFullScreenProgress = () => {
    return (
      <Backdrop
        sx={{
          color: theme.palette.primary.main,
          zIndex: (theme) => theme.zIndex.drawer + 1,
        }}
        open={isFullScreenLoading}
      >
        <CircularProgress color="secondary" />
      </Backdrop>
    );
  };

  const handleRefresh = () => {
    setProfiles([]);
    fetchData();
  };

  return (
    <DashboardContainer>
      {renderFullScreenProgress()}
      <UpperRightButtonsContainer>
        {/* <FormControlLabel
          control={
            <Switch
              color="secondary"
              checked={auditMode}
              disabled
              onChange={() => setAuditMode(!auditMode)}
            />
          }
          label={`Audit Mode ${auditMode ? "On" : "Off"}`}
          style={{ marginLeft: 16 }}
        /> */}
      </UpperRightButtonsContainer>
      {profiles.length ? (
        <div
          style={{
            display: "flex",
            alignItems: "center",
            flexDirection: "column",
            width: "100%",
          }}
        >
          <Grid container spacing={2} style={{ width: "100%" }}>
            {profiles.map((profileResponse: ProfileResponse, index: number) => (
              <Grid item xs={12} sm={6} md={4} key={index}>
                <ProfileContainer
                  profileData={profileResponse}
                  auditMode={auditMode}
                />
              </Grid>
            ))}
          </Grid>
          <Button
            style={{ marginTop: 64 }}
            disabled={profiles.length === 0}
            onClick={handleRefresh}
            variant="contained"
          >
            Load more
          </Button>
        </div>
      ) : (
        <CenterButtonContainer>
          <Typography style={{ marginBottom: 8 }} variant="h6">
            {responseMessage}
          </Typography>
          <Button variant="contained" color="primary" onClick={fetchData}>
            See profiles
          </Button>
        </CenterButtonContainer>
      )}
    </DashboardContainer>
  );
};

export default Dashboard;
