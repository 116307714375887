import React from "react";
import styled from "styled-components";
import {
  Button,
  Card,
  CardActions,
  CardContent,
  Typography,
  Chip,
} from "@mui/material";
import { green, red } from "@mui/material/colors";
import CheckIcon from "@mui/icons-material/Check";
import ClearIcon from "@mui/icons-material/Clear";
import { PricingCardProps } from "../types";

const StyledCard = styled(Card)`
  max-width: 345px;
  margin-bottom: 16px;
`;

const FeatureText = styled(Typography)<{ isActive: boolean }>`
  color: ${({ isActive }) => (isActive ? "black" : "grey")};
`;

const HeaderContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
`;

const FeatureContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  margin-top: 8px;
`;

const PricingCard: React.FC<PricingCardProps> = ({
  price,
  name,
  description,
  features,
  actionLabel,
  isMostPopular,
  onActionClick = undefined,
  actionHref = undefined,
}) => {
  return (
    <StyledCard>
      <CardContent>
        <HeaderContainer>
          <Typography variant="h5" component="h2">
            {name}
          </Typography>
          {isMostPopular && <Chip label="Most Popular" color="secondary" />}
        </HeaderContainer>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
          }}
        >
          <Typography variant="h4" fontWeight={600}>
            ${price}
          </Typography>
          <Typography variant="h6">/ month</Typography>
        </div>
        <Typography color="textSecondary">{description}</Typography>
        {features
          .sort((a, b) => Number(b.isActive) - Number(a.isActive))
          .map((feature) => (
            <FeatureContainer>
              {feature.isActive && (
                <CheckIcon style={{ marginRight: 12, color: green[500] }} />
              )}
              {!feature.isActive && (
                <ClearIcon style={{ marginRight: 12, color: red[500] }} />
              )}
              <FeatureText key={feature.name} isActive={feature.isActive}>
                {feature.name}
              </FeatureText>
            </FeatureContainer>
          ))}
      </CardContent>
      <CardActions>
        <Button
          color="secondary"
          variant={isMostPopular ? "contained" : "outlined"}
          fullWidth
          onClick={onActionClick}
          href={actionHref}
        >
          {actionLabel}
        </Button>
      </CardActions>
    </StyledCard>
  );
};

export default PricingCard;
