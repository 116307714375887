import React, { useState, useEffect } from "react";
import styled from "styled-components";
import {
  Stepper,
  Step,
  StepLabel,
  Button,
  Typography,
  Backdrop,
  CircularProgress,
  useTheme,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import { useDropzone } from "react-dropzone";
import { OnboardingStep } from "../types";
import { get, postFormData } from "../api";
import { useAuth } from "../auth/AuthContext";

const InstructionsContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 16px;
  margin-bottom: 16px;
  padding-left: 32px;
  padding-right: 32px;
  align-items: center;
`;

const ButtonsContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  margin-top: 32px;
  align-items: center;
  width: 100%;
  margin-bottom: 32px;
`;

const DropzoneContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 16px;
  margin-bottom: 16px;
  align-items: center;
`;

const PageContainer = styled.div`
  margin-top: 16px;
`;

const Onboarding: React.FC = () => {
  const theme = useTheme();
  const navigate = useNavigate();
  const { logout } = useAuth();
  const [activeStep, setActiveStep] = useState(0);
  const [steps, setSteps] = useState<OnboardingStep[]>([]);
  const [isFullScreenLoading, setIsFullScreenLoading] = useState(false);

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    setIsFullScreenLoading(true);
    const { result, status } = await get(`/onboarding`, logout);
    setSteps(result);
    setIsFullScreenLoading(false);
  };

  const handleNext = () => {
    if (activeStep < steps.length - 1) {
      setActiveStep((prevStep) => prevStep + 1);
    }
  };

  const handleBack = () => {
    if (activeStep > 0) {
      setActiveStep((prevStep) => prevStep - 1);
    }
  };

  const renderFullScreenProgress = () => {
    return (
      <Backdrop
        sx={{
          color: theme.palette.primary.main,
          zIndex: (theme) => theme.zIndex.drawer + 1,
        }}
        open={isFullScreenLoading}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
    );
  };

  const { getRootProps, getInputProps } = useDropzone({
    onDrop: async (acceptedFiles) => {
      setIsFullScreenLoading(true);
      if (acceptedFiles.length === 0) {
        alert("Please select a file to upload.");
        return;
      }

      const fileToUpload = acceptedFiles[0];
      console.log(fileToUpload);
      const formData = new FormData();
      formData.append("file", fileToUpload);
      console.log(formData.get("file"));
      const { result, status } = await postFormData(
        "/upload/log-file",
        formData,
        logout
      );
      if (result && result.isOnboarded) {
        localStorage.setItem("isOnboarded", result.isOnboarded);
        navigate("/account");
      }
      setIsFullScreenLoading(false);
    },
  });

  const renderSteps = () => {
    return (
      <PageContainer>
        <Stepper activeStep={activeStep} alternativeLabel>
          {steps.map((step, index) => (
            <Step key={step.label}>
              <StepLabel>{step.label}</StepLabel>
            </Step>
          ))}
        </Stepper>

        <InstructionsContainer>
          <Typography
            variant="h6"
            dangerouslySetInnerHTML={{ __html: steps[activeStep].description }}
          />
          {/* <Typography variant="h6">{steps[activeStep].description}</Typography> */}
          {steps[activeStep].imgSrc && (
            <img
              style={{ height: "400px", width: "700px", marginTop: "16px" }}
              src={steps[activeStep].imgSrc}
              alt={`Step ${activeStep + 1}`}
            />
          )}
        </InstructionsContainer>

        {activeStep === steps.length - 1 ? (
          <DropzoneContainer>
            <Typography variant="h6">
              Upload the file you downloaded from Requestly
            </Typography>
            <div
              {...getRootProps()}
              style={{
                backgroundColor: "#ececec",
                borderRadius: 2,
                borderStyle: "dashed",
                marginBottom: 10,
                width: "50%",
                minHeight: 75,
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <input {...getInputProps()} />
              <p>Drag 'n' drop the downloaded log file here</p>
            </div>
          </DropzoneContainer>
        ) : (
          <ButtonsContainer>
            <Button variant="contained" color="primary" onClick={handleBack}>
              Back
            </Button>

            <Button variant="contained" color="primary" onClick={handleNext}>
              Next
            </Button>
          </ButtonsContainer>
        )}
      </PageContainer>
    );
  };

  return (
    <div>
      {isFullScreenLoading && renderFullScreenProgress()}
      {steps.length > 0 && renderSteps()}
    </div>
  );
};

export default Onboarding;
