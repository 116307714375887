import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { loadStripe } from "@stripe/stripe-js";
import {
  TextField,
  Switch,
  Button,
  FormControlLabel,
  CircularProgress,
  Typography,
  useTheme,
  Backdrop,
} from "@mui/material";
import {
  EmbeddedCheckoutProvider,
  EmbeddedCheckout,
} from "@stripe/react-stripe-js";
import { STRIPE_KEY } from "../constants";
import { post, get } from "../api";
import { useAuth } from "../auth/AuthContext";

const stripePromise = loadStripe(STRIPE_KEY);

const AccountContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 32px;
`;

const AuditSwitchContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin-bottom: 16px;
`;

const SubscriptionContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 16px;
`;

interface AccountObj {
  email: string;
  auditMode: boolean;
  isTrialing: boolean;
  trialEndDate: string;
  subscriptionActive: boolean;
  subscriptionRenewalDate: string;
  subscriptionEndDate: string;
  subscriptionPrice: string;
  isEnabled: boolean;
  hasAnySubscription: boolean;
}

const EmptyAccount: AccountObj = {
  email: "",
  auditMode: false,
  isTrialing: false,
  trialEndDate: "",
  subscriptionActive: false,
  subscriptionRenewalDate: "",
  subscriptionEndDate: "",
  subscriptionPrice: "",
  isEnabled: false,
  hasAnySubscription: false,
};

const Account: React.FC = () => {
  const theme = useTheme();
  const { logout } = useAuth();
  const [account, setAccount] = useState<AccountObj>(EmptyAccount);
  const [auditMode, setAuditMode] = useState(false);
  const [clientSecret, setClientSecret] = useState("");
  const [showCheckout, setShowCheckout] = useState<boolean | null>(null);
  const [isAuditChangeLoading, setIsAuditChangeLoading] = useState(false);
  const [isAccountLoading, setIsAccountLoading] = useState(false);

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    setIsAccountLoading(true);
    const { result, status } = await get("/accounts", logout);
    if (result) {
      setAccount(result);
      setAuditMode(result.auditMode);
    }
    setIsAccountLoading(false);
  };

  const handleAuditChange = async () => {
    setIsAuditChangeLoading(true);
    const { result, status } = await post(
      "/accounts/",
      { auditMode: !auditMode },
      logout
    );
    setIsAuditChangeLoading(false);
    setAuditMode(!auditMode);
  };

  const handleSubscriptionClick = async () => {
    setIsAccountLoading(true);
    const { result, status } = await post(
      "/accounts/subscriptions",
      { action: account.subscriptionActive ? "cancel" : "create" },
      logout
    );
    if (!account.subscriptionActive && result.clientSecret) {
      setClientSecret(result.clientSecret);
      setShowCheckout(true);
    } else if (account.subscriptionActive) {
      setAccount(result);
    }
    setIsAccountLoading(false);
  };

  const handleLogout = async () => {
    const { result, status } = await post("/logout/", {}, logout);
    logout(true);
  };

  const renderFullScreenProgress = () => {
    return (
      <Backdrop
        sx={{
          color: theme.palette.primary.main,
          zIndex: (theme) => theme.zIndex.drawer + 1,
        }}
        open={isAccountLoading}
      >
        <CircularProgress color="secondary" />
      </Backdrop>
    );
  };

  const getButtonText = () => {
    if (!account.hasAnySubscription) {
      return "Start free trial";
    } else if (!account.subscriptionActive) {
      return "Purchase Subscription";
    } else if (account.subscriptionEndDate) {
      return "Subscription Canceled";
    } else if (account.isTrialing) {
      return "Cancel Trial";
    } else {
      return "Cancel Subscription";
    }
  };

  if (isAccountLoading) {
    return renderFullScreenProgress();
  }

  const getSubscriptionText = () => {
    if (account.isTrialing && account.trialEndDate) {
      return `Your free trial ends on ${account.trialEndDate}`;
    } else if (account.subscriptionActive && account.subscriptionEndDate) {
      return `Your subscription will end on ${account.subscriptionEndDate}`;
    } else if (account.subscriptionActive && account.subscriptionRenewalDate) {
      return `Your subscription will renew on ${account.subscriptionRenewalDate}`;
    }
  };

  return (
    <AccountContainer>
      <h2>Account</h2>
      <TextField
        style={{ minWidth: 400, marginBottom: 16 }}
        label="Email"
        value={account.email}
        disabled
        margin="normal"
      />
      <AuditSwitchContainer>
        <FormControlLabel
          control={
            <Switch
              disabled={isAuditChangeLoading}
              checked={auditMode}
              onChange={handleAuditChange}
              color="secondary"
            />
          }
          disabled
          label="Default Audit Mode"
          labelPlacement="start"
          style={{ marginRight: 8 }}
        />
        {isAuditChangeLoading && (
          <CircularProgress color="secondary" size={24} />
        )}
      </AuditSwitchContainer>

      <SubscriptionContainer>
        <Button
          style={{ marginBottom: 16 }}
          variant="contained"
          color={account.subscriptionActive ? "primary" : "secondary"}
          onClick={handleSubscriptionClick}
          disabled={account.subscriptionEndDate ? true : false}
        >
          {getButtonText()}
        </Button>
        <Typography variant="body1" style={{ marginBottom: 16 }}>
          {getSubscriptionText()}
        </Typography>
        <Typography variant="body1">
          <b>Note</b>: The charge on your credit card statement will appear as
          <b>Summer Lemonade Inc.</b> or <b>SummerLemo</b>
        </Typography>
      </SubscriptionContainer>
      <Button variant="outlined" onClick={handleLogout}>
        Logout
      </Button>
      {showCheckout && (
        <div id="checkout">
          {clientSecret && (
            <EmbeddedCheckoutProvider
              stripe={stripePromise}
              options={{ clientSecret }}
            >
              <EmbeddedCheckout />
            </EmbeddedCheckoutProvider>
          )}
        </div>
      )}
    </AccountContainer>
  );
};

export default Account;
