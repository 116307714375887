import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { useNavigate } from "react-router-dom";
import { Typography, Button } from "@mui/material";
import Therapy from "../therapy.png";
import Dog from "../dog.png";
import { TypingEffect, PricingCard, Signup } from "../components";

const HomeContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const HeroSection = styled.div`
  display: flex;
  flex-direction: row;
  margin: 64px;
  align-items: center;
  justify-content: space-between;
  @media (max-width: 1024px) {
    flex-direction: column;
  }
`;

const RightImageContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  @media (max-width: 1024px) {
    flex-direction: column;
  }
`;

const Image = styled.img`
  width: 340px;
  height: 243px;
  object-fit: contain;
  margin-bottom: 32px;
  margin-right: 16px;
  @media (max-width: 1024px) {
    margin-right: 0px;
  }
`;

const LeftHeroContentContainer = styled.div`
  width: 30%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-evenly;
  @media (max-width: 1024px) {
    width: 100%;
    align-items: center;
  }
`;

const RightHeroContentContainer = styled.div`
  background-color: rgba(254, 99, 61, 0.1);
  width: 70%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 16px;
  @media (max-width: 1024px) {
    width: 100%;
  }
`;

const PricingContainer = styled.div`
  margin: 32px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
  width: 100%;
`;

const CardContainer = styled.div`
  margin-top: 32px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-around;
  width: 60%;
  @media (max-width: 1024px) {
    flex-direction: column;
    width: 100%;
  }
`;

const ExplainerContainer = styled.div`
  margin: 32px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
  width: 100%;
  background-color: rgba(254, 99, 61, 0.3);
`;

const GifImage = styled.img`
  object-fit: contain;
  max-width: 320px;
  width: 50%;
  margin-top: 16px;
  margin-bottom: 32px;
  @media (max-width: 1024px) {
    width: 100%;
  }
`;

const VerticalLine = styled.div`
  height: 60px;
  width: 1px;
  background-color: #000;
  margin: 0 16px;
`;

const Home: React.FC = () => {
  const navigate = useNavigate();
  const [showSignup, setShowSignup] = useState(false);
  const [hasShownPopup, setHasShownPopup] = useState(false);

  useEffect(() => {
    if (localStorage.getItem("dataisession")) {
      navigate("/dashboard");
    } else {
      if (hasShownPopup) {
        return;
      }
      const timer = setTimeout(() => {
        setShowSignup(true);
        setHasShownPopup(true);
      }, 30000);
      return () => clearTimeout(timer);
    }
  });

  const scrollToAnchor = (anchorId: string) => {
    const anchorElement = document.getElementById(anchorId);
    if (anchorElement) {
      anchorElement.scrollIntoView({ behavior: "smooth", block: "start" });
    }
  };

  return (
    <HomeContainer>
      <HeroSection>
        <LeftHeroContentContainer>
          <TypingEffect
            sentence={"Get more "}
            words={["matches", "time", "rizz", "dates"]}
          />
          <Typography variant="h5" style={{ marginTop: 32 }}>
            Your personal AI dating assistant.
          </Typography>
          <Button
            style={{ marginTop: 32, marginBottom: 16 }}
            variant="contained"
            color="secondary"
            onClick={() => scrollToAnchor("pricing")}
          >
            Learn more
          </Button>
        </LeftHeroContentContainer>
        <RightHeroContentContainer>
          <Typography
            variant="h5"
            fontWeight={600}
            style={{ marginTop: 16, marginBottom: 32 }}
          >
            Can you tell which response is AI generated?
          </Typography>
          <RightImageContainer>
            <Image src={Therapy} alt={"GPTMatch.me logo"} />
            <Image src={Dog} alt={"GPTMatch.me logo"} />
          </RightImageContainer>
        </RightHeroContentContainer>
      </HeroSection>
      <ExplainerContainer>
        <Typography
          variant="h4"
          fontWeight={600}
          style={{ textAlign: "center", margin: 16 }}
        >
          Spend less time on apps and more time on dates.
        </Typography>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <Typography
            variant="h6"
            fontWeight={600}
            style={{ textAlign: "left", margin: 16 }}
          >
            Connect your dating app account
          </Typography>
          <VerticalLine />
          <Typography
            variant="h6"
            fontWeight={600}
            style={{ textAlign: "left", margin: 16 }}
          >
            Scroll through profiles
          </Typography>
          <VerticalLine />
          <Typography
            variant="h6"
            fontWeight={600}
            style={{ textAlign: "left", margin: 16 }}
          >
            Generate and send responses using AI
          </Typography>
        </div>
        <GifImage
          src="https://datai-public.s3.us-east-2.amazonaws.com/gptmatch-feed.gif"
          alt="GPTMatch.me feed"
        />
      </ExplainerContainer>
      <PricingContainer id="pricing">
        <Typography
          variant="h4"
          fontWeight={600}
          style={{ textAlign: "center", margin: 16 }}
        >
          90% cheaper than a human dating assistant
        </Typography>
        <Typography variant="h5">But twice as powerful</Typography>
        <CardContainer>
          <PricingCard
            isMostPopular
            price={"19"}
            name={"Standard"}
            description={"Save yourself time and get more matches."}
            actionLabel={"Start free trial"}
            onActionClick={() => setShowSignup(true)}
            features={[
              {
                name: "Up to 200 likes per month (unlimited skips)",
                isActive: true,
              },
              {
                name: "Streamlined swiping system",
                isActive: true,
              },
              { name: "AI-assisted profile responses", isActive: true },
              {
                name: "Autobiographical responses",
                isActive: false,
              },
              {
                name: "AI crafts messages in your style",
                isActive: false,
              },
              {
                name: "$0.35 per additional like above 200",
                isActive: true,
              },
              {
                name: "Apps supported: Hinge",
                isActive: true,
              },
            ]}
          />
          <PricingCard
            price={"99"}
            name={"Premium"}
            description={"A true extension of yourself."}
            actionLabel={"Contact us"}
            actionHref={`mailto:support@summerlemonade.xyz?subject=${encodeURIComponent(
              "Premium Demo"
            )}`}
            features={[
              {
                name: "Up to 500 likes per month (unlimited skips)",
                isActive: true,
              },
              {
                name: "Streamlined swiping system",
                isActive: true,
              },
              { name: "AI-assisted profile responses", isActive: true },
              {
                name: "$0.20 per additional like above 500",
                isActive: true,
              },
              {
                name: "Apps supported: Hinge",
                isActive: true,
              },
              {
                name: "Autobiographical responses",
                isActive: true,
              },
              {
                name: "AI crafts messages in your style",
                isActive: true,
              },
            ]}
          />
        </CardContainer>
      </PricingContainer>
      {showSignup && <Signup open onClose={() => setShowSignup(false)} />}
      <a
        style={{ marginBottom: 32 }}
        href="https://theresanaiforthat.com/ai/gptmatch-me/?ref=featured&v=663693"
        target="_blank"
        rel="noreferrer"
      >
        <img
          width="300"
          src="https://media.theresanaiforthat.com/featured3.png"
          alt="Featured on There's an AI for That"
        />
      </a>
    </HomeContainer>
  );
};

export default Home;
